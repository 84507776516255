import React, {useEffect, useContext} from 'react';
import moment from 'moment';

import ReactMapboxGl, {MapContext, ZoomControl, GeoJSONLayer} from 'react-mapbox-gl';
import {useDispatch, useSelector} from 'react-redux';
import {useAPI} from '../../api/fields/use-api';
import {Loader} from '../../components/loader';
import {useStateWithLocalStorage} from '../../hooks/use-state-with-storage';

import 'mapbox-gl/dist/mapbox-gl.css';

const symbolLayout = {
    "text-field": "{place}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0],
    "text-size": 10,
    "text-anchor": "bottom",
    "text-allow-overlap": true


};
const symbolPaint = {
    'text-color': 'white',
};

const polygonLayout = {visibility: 'visible'};
const polygonPaint = {
    'fill-color': 'green',
    'fill-opacity': 0.8

};


const sharePaint = (color) => ({
    'fill-color': color,
    'fill-outline-color': color,
    'fill-opacity': 0.8,
});


const linePaint = {
    'line-color': 'black',
    'line-opacity': 1,
    'line-width': 2
}

const initBounds = {
    swLng: 31.937222613289606,
    swLat: 49.39850281447136,
    neLng: 31.95180122583369,
    neLat: 49.39349561845589,
};

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

function Resizer({size}) {
    const map = useContext(MapContext);

    useEffect(() => {
        map.resize();
    }, [size]);

    return null;
}

export function MapPage({size}) {
    const dispatch = useDispatch();

    const {fields, currentFieldId, currentShareId, mapBoxMode} = useSelector((state) => state);


    const {bounds: boundsFromStore} = useSelector((state) => state);

    const [zoom, setZoom] = useStateWithLocalStorage({
        key: 'map.zoom',
        defaultValue: 11,
    });

    const [center, setCenter] = useStateWithLocalStorage({
        key: 'map.center',
        defaultValue: [31.937222613289606, 49.39850281447136],
    });

    const [bounds, setBounds] = useStateWithLocalStorage({
        key: 'map.bounds',
        defaultValue: initBounds,
    });

    const {data, isLoading} = useAPI(bounds, zoom);

    useEffect(() => {
        if (boundsFromStore) {
            setBounds(boundsFromStore);
        }
    }, [boundsFromStore]);

    console.log({fields, data: toGeoJson(fields)})

    useEffect(() => {
        if (data) {
            dispatch({type: 'SET_FIELDS', value: data.content});
        }
    }, [dispatch, data]);

    const handleZoomEnd = (e) => {
        const bounds = {
            neLng: e.getBounds().getNorthEast().lng,
            neLat: e.getBounds().getNorthEast().lat,
            swLat: e.getBounds().getSouthWest().lat,
            swLng: e.getBounds().getSouthWest().lng,
        };
        setBounds(bounds);
    };


    if (isLoading) return <Loader text="Идет загрузка геозон..."/>;

    return (
        <Map
            zoom={[zoom]}
            center={center}
            style="mapbox://styles/xfisherbox/ckx2cxwus41rm15ns8sr5qqmh"
            onZoomEnd={(e) => {
                setZoom(e.getZoom());
                handleZoomEnd(e);
                //setCenter(e.getCenter());
            }}
            onMoveEnd={(e) => {
                handleZoomEnd(e);
            }}
            movingMethod="jumpTo"
            fitBoundsOptions={{duration: 0}}
            containerStyle={{
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            }}
        >
            <ZoomControl style={{top: 80}}/>
            <Resizer size={size}/>

            {toGeoJson(fields)}

            {/*<GeoJSONLayer*/}
            {/*    symbolLayout={symbolLayout}*/}
            {/*    symbolPaint={symbolPaint}*/}
            {/*    polygonLayout={polygonLayout}*/}
            {/*    fillPaint={polygonPaint}*/}
            {/*    linePaint={linePaint}*/}
            {/*    data={{*/}
            {/*        "type": "FeatureCollection",*/}
            {/*        "features": toGeoJson(fields)*/}
            {/*    }}*/}
            {/*    symbolLayout={{*/}
            {/*        "text-field": "{place}",*/}
            {/*        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],*/}
            {/*        "text-offset": [0, 0.6],*/}
            {/*        "text-size": 10,*/}
            {/*        "text-anchor": "top",*/}

            {/*    }}/>*/}
            {/*<PolygonViewer />*/}
        </Map>
    );
}

function toGeoJson(data: any) {

    if (!data?.length) return [];

    const res = data.map(f => {

        return {
            "type": "Feature",
            "properties": {
                "place":  f.calculatedArea + ' га\n' + f.geocodeAddress,
                shares: f.shares,
            },
            "geometry": f.polygon
        }
    });


    const result = res.map(geo => {
        return (<>

            <GeoJSONLayer
                fillPaint={polygonPaint}
                linePaint={linePaint}
                data={{
                    "type": "FeatureCollection",
                    "features": [geo]
                }}
                />

            <GeoJSONLayer
                symbolLayout={symbolLayout}
                symbolPaint={symbolPaint}

                data={{
                    "type": "FeatureCollection",
                    "features": [geo]
                }}
            />
            {geo?.properties?.shares && geo.properties.shares.map(share => {
                return <><GeoJSONLayer
                    polygonLayout={polygonLayout}
                    fillPaint={getSharePaintByContract(share?.contract)}
                    linePaint={linePaint}
                    data={{
                        "type": "FeatureCollection",
                        "features": [{
                            "type": "Feature",
                            "properties": {place: share.cadastral + "\n" + share.calculatedArea + ' га' + `\n(${getDays(share?.contract)} днів)`},
                            "geometry": share.polygon
                        }]
                    }}/>
                    <GeoJSONLayer
                        symbolLayout={symbolLayout}
                        symbolPaint={symbolPaint}
                        data={{
                            "type": "FeatureCollection",
                            "features": [{
                                "type": "Feature",
                                "properties": {place: share.cadastral + "\n" + share.calculatedArea + ' га' + `\n(${getDays(share?.contract)} днів)`},
                                "geometry": share.polygon
                            }]
                        }}
                        symbolLayout={{
                            "text-field": "{place}",
                            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                            "text-offset": [0, 0],
                            "text-size": 12,
                            "text-anchor": "center",
                            'text-justify': 'auto',
                            "text-allow-overlap": true,

                        }}/>
                </>
            })}
        </>)
    })


    return result;

}

function getSharePaintByContract(contract) {
    const contractStatus = contract?.status;
    if (contractStatus === 'ACTIVE') {
        const totalDays = moment(contract.endDate).diff(moment(contract.signingDate), 'days');
        if (totalDays < 30) {
            return sharePaint('red')
        } else if (totalDays < 120) {
            return sharePaint('yellow')
        } else if (totalDays < 360) {
            return sharePaint('#00AA00')
        } else if (totalDays < 0) {
            return sharePaint('orange');
        }
        return sharePaint('blue')

    }
    return sharePaint('grey')
}

function getDays(contract) {

    const contractStatus = contract?.status;
    if (contractStatus === 'ACTIVE') {
        return moment(contract.endDate).diff(moment(contract.signingDate), 'days');
    }

    return 0;

}


export default MapPage;
