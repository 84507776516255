import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LayersIcon from '@material-ui/icons/Layers';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import { polygonToImage } from '../../utils/polygon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function getWithoutCountry(address) {
  if (!address) return null;
  const parts = address.split(', ');
  return parts[0] + ' ' + parts[1];
}

export const FieldItem = ({ field }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentFieldId, currentShareId } = useSelector((state) => state);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          handleClick();
        }}
        selected={currentFieldId === field.id ? true : false}
      >
        <ListItemAvatar>
          <Avatar style={{ width: 32, height: 32, margin: 4, backgroundColor: 'green' }}>
            <EditIcon
              style={{ color: 'white' }}
              onClick={() => {
                history.push('/field-editor/' + field.id);
              }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Поле (${field.calculatedArea} га) [${field.shares.length}]`}
          secondary={getWithoutCountry(field.geocodeAddress)}
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch({ type: 'SET_CURRENT_FIELD_ID', value: field.id });
          }}
        >
          <img src={polygonToImage(field)} />
        </div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {field.shares &&
            field.shares.map((share) => (
              <ListItem
                button
                className={classes.nested}
                selected={currentShareId === share.id ? true : false}
                onClick={() => {
                  dispatch({ type: 'SET_CURRENT_SHARE_ID', value: share.id });
                }}
              >
                <ListItemAvatar>
                  <Avatar style={{ width: 32, height: 32, margin: 4, backgroundColor: 'green' }}>
                    <EditIcon
                      style={{ color: 'white' }}
                      onClick={() => {
                        history.push('/share-editor/' + share.id);
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${share.calculatedArea} га`} secondary={`${share.id}`} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </>
  );
};
