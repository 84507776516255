import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { FieldsEditor } from '../pages/editor';
import { FieldEditor } from '../pages/field-editor';
import { Fields } from '../pages/fields';

import { ShareEditor } from '../pages/share-editor';
import { Contractors } from '../pages/contractors';
import { Contracts } from '../pages/contracts';
import { ContractEditor } from '../pages/contract-editor';

import { ContractorEditor } from '../pages/contractor-editor';
import { FieldViewer } from '../pages/fields-list';
import { Shares } from '../pages/shares';
import { Debug } from '../pages/debug';

import { Loader } from '../components/loader';
import { setKeyCloak } from '../data/network/http';
import { Bar } from '../components/app-bar';
export const App = () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <Loader text="Идет загрузка данных..." />;
  }

  if (keycloak.token != null) {
    setKeyCloak(keycloak);
  } else {
    keycloak.login();
  }

  return (
    <>
      <Router>
        <Bar />
        <Switch>
          <Route path="/fields-editor">
            <FieldsEditor />
          </Route>
          <Route path="/fields">
            <Fields />
          </Route>
          <Route path="/field-editor/:id">
            <FieldEditor />
          </Route>
          <Route path="/share-editor/:id">
            <ShareEditor />
          </Route>
          <Route path="/contracts">
            <Contracts />
          </Route>
          <Route path="/contractors">
            <Contractors />
          </Route>
          <Route path="/contract-editor/:id?">
            <ContractEditor />
          </Route>
          <Route path="/contractor-editor/:id?">
            <ContractorEditor />
          </Route>
          <Route path="/field-viewer">
            <FieldViewer />
          </Route>
          <Route path="/shares">
            <Shares />
          </Route>
          <Route path="/debug">
            <Debug />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
