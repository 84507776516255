import React, { useEffect,  useContext } from 'react';
import ReactMapboxGl, { MapContext, ZoomControl } from 'react-mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useAPI } from '../../api/fields/use-api';
import { PolygonEditor } from './polygon-editor';
import { Controls } from './controls';
import { Loader } from '../../components/loader';
import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';

import 'mapbox-gl/dist/mapbox-gl.css';

const initBounds = {
  swLng: 31.937222613289606,
  swLat: 49.39850281447136,
  neLng: 31.95180122583369,
  neLat: 49.39349561845589,
};

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

function Resizer({ size }) {
  const map = useContext(MapContext);

  useEffect(() => {
    map.resize();
  }, [size]);

  return null;
}

export function MapPage({ size }) {
  const dispatch = useDispatch();

  const { bounds: boundsFromStore } = useSelector((state) => state);

  const [zoom, setZoom] = useStateWithLocalStorage({
    key: 'map.zoom',
    defaultValue: 11,
  });

  const [center, setCenter] = useStateWithLocalStorage({
    key: 'map.center',
    defaultValue: [31.937222613289606, 49.39850281447136],
  });

  const [bounds, setBounds] = useStateWithLocalStorage({
    key: 'map.bounds',
    defaultValue: initBounds,
  });

  const { data, isLoading } = useAPI(bounds, zoom);

  useEffect(() => {
    if (boundsFromStore) {
      setBounds(boundsFromStore);
    }
  }, [boundsFromStore]);

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_FIELDS', value: data.content });
    }
  }, [dispatch, data]);

  const handleZoomEnd = (e) => {
    const bounds = {
      neLng: e.getBounds().getNorthEast().lng,
      neLat: e.getBounds().getNorthEast().lat,
      swLat: e.getBounds().getSouthWest().lat,
      swLng: e.getBounds().getSouthWest().lng,
    };
    setBounds(bounds);
  };

  if (isLoading) return <Loader text="Идет загрузка геозон..." />;

  return (
    <Map
      zoom={[zoom]}
      center={center}
      style="mapbox://styles/xfisherbox/ckx2cxwus41rm15ns8sr5qqmh"
      onZoomEnd={(e) => {
        setZoom(e.getZoom());
        handleZoomEnd(e);
        //setCenter(e.getCenter());
      }}
      onMoveEnd={(e) => {
        handleZoomEnd(e);
      }}
      movingMethod="jumpTo"
      fitBoundsOptions={{ duration: 0 }}
      containerStyle={{
        backgroundColor: 'black',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <ZoomControl style={{ top: 80 }} />
      <Resizer size={size} />
      <Controls />
      <PolygonEditor />
    </Map>
  );
}

export default MapPage;
