import React, { useState, useEffect } from 'react';
import { Grid, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  date: {
    margin: 4,
  },
});

const Calendar = ({ label = 'Дата', value, onChange = () => {}, helperText, error }) => {
  const [date, setDate] = useState(moment(new Date()).startOf('day').utc());

  useEffect(() => {
    onChange(date);
  }, [date]);

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const handleDateChange = (date) => {
    setDate(moment(date).startOf('day').utc().toISOString());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <KeyboardDatePicker
        label={label}
        margin="normal"
        format="d MMMM"
        id="mui-pickers-date"
        value={date}
        onChange={handleDateChange}
        error={error}
        helperText={helperText}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(Calendar);
