import { createStore } from 'redux';
import { reducer } from './reducer';

const initalState = {
  fields: [],
  currentFieldId: null,
  currentShareId: null,
  mapBoxMode: null,
  currentPoint: null,
  bounds: null,
};

const store = createStore(reducer, initalState);

export default store;
