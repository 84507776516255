export const CONTRACTOR_TYPES = {
  PHYSICAL: 'Физ. лицо',
  LEGAL: 'Юр. лицо',
  SOLE_PROPRIETOR: 'Физ. лицо предприниматель',
};

export const EContractStatus = {
  CREATED: 'CREATED',
  EXPIRED: 'EXPIRED',
  ACTIVE: 'ACTIVE'
}
