import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { contractAPI } from '../contract-controller';

export const useAPI = ({ page, size, orderBy, order }) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['contracts', { page, size, orderBy, order }],
    () => contractAPI.all({ page, size, orderBy, order }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAPI = ({ id }) => {
  const { isLoading, error, data } = useQuery(['contract', { id }], () => contractAPI.get({ id }), {
    onError: (error) => {
      NotificationManager.error(error.message);
    },
    enabled: !!id,
  });

  return { isLoading, error, data };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    (data) => contractAPI.create(data),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Договор создан!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания договора!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    (data) => contractAPI.update(data),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Договор изменен!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка изменения договора!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => contractAPI.delete({ id }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Договор удален!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления договора!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
