import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { shareAPI } from '../share-controller';

export const useAPI = ({ neLng, neLat, swLng, swLat }, zoom) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['shares', { neLng, neLat, swLng, swLat, zoom }],
    () => shareAPI.all({ neLng, neLat, swLng, swLat, zoom }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAllAPI = ({ page, size, sort }) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['shares', { page, size, sort }],
    () => shareAPI.pageableAll({ page, size, sort }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAPI = ({ id }) => {
  const { isLoading, error, data } = useQuery(['share', { id }], () => shareAPI.get({ id }), {
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, error, data };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ coordinates }) => shareAPI.create({ coordinates }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Пай создан!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания пая!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id, coordinates, parentId }) => shareAPI.update({ id, coordinates, parentId }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Пай изменен!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка изменения пая!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => shareAPI.delete({ id }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Пай удален!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления пая!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
