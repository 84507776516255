import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';

import { contractorAPI } from '../../api/contractor-controller';

export const useAPI = ({ partName, page, size, order, orderBy }) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['contractors', { partName, page, size, order, orderBy }],
    () => contractorAPI.all({ partName, page, size, order, orderBy }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAPI = ({ id }) => {
  const { isLoading, error, data } = useQuery(
    ['contractor', id],
    (id) => {
      contractorAPI.get({ id });
    },
    {
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, error, data };
};
