import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getFieldEndpoint = () => {
  return API_ENDPOINT + '/image';
};

export const imageAPI = {
  create: (data) => {
    const formData = new FormData();

    for (const image of data.images) {
      formData.append('images', image);
    }

    return axios({
      method: 'post',
      url: getFieldEndpoint(),
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
