import axios from 'axios';
import { uuid } from 'uuidv4';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getShareEndpoint = () => {
  return API_ENDPOINT + '/share';
};

export const shareAPI = {
  all: async ({ cadastral, size, page, orderBy, order }) => {
    const { data } = await axios.get(getShareEndpoint(), {
      params: {
        size,
        page,
        cadastral,
        sort: `${orderBy},${order}`,
      },
    });
    return data;
  },

  get: async ({ id }) => {
    const { data } = await axios.get(getShareEndpoint() + '/' + id);
    return data;
  },

  create: ({ parentId, coordinates }) => {
    return axios({
      method: 'post',
      url: getShareEndpoint(),
      data: {
        id: uuid(),
        parentId,
        polygon: {
          coordinates,
          type: 'Polygon',
        },
      },
    });
  },
  update: ({ id, parentId, coordinates }) => {
    return axios({
      method: 'post',
      url: getShareEndpoint(),
      data: {
        id,
        parentId,
        polygon: {
          coordinates,
          type: 'Polygon',
        },
      },
    });
  },
  delete: ({ id }) => {
    return axios.delete(getShareEndpoint() + '/' + id);
  },
};
