import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDeleteAPI } from '../../api/fields/use-api';

export const useDeleteGeozone = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data, isSuccess } = useDeleteAPI();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch({ type: 'REMOVE_FIELD', value: data });
    }
  }, [dispatch, data, isSuccess]);

  const onDeleteArea = useCallback(
    (e) => {
      e.features.forEach((area) => mutate({ id: area.id }));
    },
    [mutate],
  );

  return onDeleteArea;
};
