import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { useUpdateAPI, useCreateAPI } from '../../api/contracts/use-api';

export const useUpdateContract = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data, isSuccess } = useUpdateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      //NotificationManager.info('Договор был обновлен');
    }
  }, [dispatch, data, isSuccess]);

  const onUpdateContract = useCallback((data) => mutate(data), [mutate]);

  return onUpdateContract;
};

export const useCreateContract = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      history.push('/contract-editor/' + data.data.id);
    }
  }, [dispatch, data, isSuccess]);

  const onCreateContact = useCallback((data) => mutate(data), [mutate]);

  return onCreateContact;
};
