import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';

import { useUpdateAPI } from '../../api/shares/use-api';

export const useUpdateShare = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate, isLoading, data, error, isSuccess } = useUpdateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      queryClient.invalidateQueries('share');
    }
  }, [dispatch, data, isSuccess]);
  useEffect(() => {
    if (error) NotificationManager.error(error);
  }, [error]);

  const onUpdateArea = useCallback(
    (area) => {
      mutate({ id: area.id, parentId: area.parentId, coordinates: area.polygon.coordinates });
    },
    [mutate],
  );

  return onUpdateArea;
};
