import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const contractEndpoint = API_ENDPOINT + '/contract';

export const contractAPI = {
  all: async ({ size, page, orderBy, order }) => {
    const { data } = await axios.get(contractEndpoint, {
      params: {
        size,
        page,
        sort: orderBy + ',' + order,
        days: 1000,
      },
    });
    return data;
  },

  get: async ({ id }) => {
    const { data } = await axios.get(contractEndpoint + '/' + id);
    return data;
  },

  create: (data) => {
    return axios({
      method: 'post',
      url: contractEndpoint,
      data,
    });
  },
  update: (data) => {
    return axios({
      method: 'post',
      url: contractEndpoint,
      data,
    });
  },
  delete: ({ id }) => {
    return axios.delete(contractEndpoint + '/' + id);
  },
};
