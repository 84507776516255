import React from 'react';
import turf from 'turf';

import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Typography, Tooltip, Grid } from '@material-ui/core';

import { useGetAPI } from '../../api/shares/use-api';
import { Loader } from '../../components/loader';
import PolygonTable from './polygon-table';

export const ShareEditor = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isLoading, error } = useGetAPI({ id });

  if (isLoading) {
    return <Loader text="Загрузка данных пая..." />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <div style={{ padding: 8 }}>
      <Button
        style={{ margin: 4 }}
        variant="outlined"
        onClick={() => history.push('/field-viewer')}
      >
        &lt; Справочник полей
      </Button>
      <Button
        style={{ margin: 4 }}
        variant="outlined"
        onClick={() => {
          const bbox = turf.bbox(data.polygon);

          dispatch({
            type: 'SET_BOUNDS',
            value: {
              swLng: bbox[0],
              swLat: bbox[1],
              neLng: bbox[2],
              neLat: bbox[3],
            },
          });

          dispatch({ type: 'SET_CURRENT_SHARE_ID', value: id });

          history.push('/fields-editor');
        }}
      >
        Показать на карте
      </Button>
      <Typography variant="h6" component="h2">
        Пай ({data?.calculatedArea} га)
        <Tooltip
          title="Скопировать в буфер"
          onClick={() => navigator.clipboard.writeText(data?.id)}
        >
          <Button>{data?.id}</Button>
        </Tooltip>
      </Typography>
      <Grid container direction="row">
        <Grid item></Grid>
        <Grid item style={{ padding: 8 }}>
          <PolygonTable data={data} />
        </Grid>
      </Grid>
    </div>
  );
};
