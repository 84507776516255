import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateAPI } from '../../api/fields/use-api';

export const useCreateGeozone = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  useEffect(() => {
    if (data?.data && isSuccess) {
      dispatch({ type: 'ADD_FIELD', value: data.data });
    }
  }, [dispatch, data, isSuccess]);

  const onCreateArea = useCallback(
    (e) => {
      e.features.forEach((area) => {
        mutate({ coordinates: area.geometry.coordinates });
      });
    },
    [mutate],
  );

  return onCreateArea;
};
