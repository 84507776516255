import { AREA_TYPE } from './consts';

export const draw = new window.MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  defaultMode: 'draw_polygon',
  styles: [
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'white',
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': [
          'case',
          ['==', ['get', 'user_class_id'], AREA_TYPE.FIELD],
          '#33FF00',
          ['==', ['get', 'user_class_id'], AREA_TYPE.SHARE],
          'yellow',
          ['==', ['get', 'user_class_id'], AREA_TYPE.ACTIVE_FIELD],
          'orange',
          ['==', ['get', 'user_class_id'], AREA_TYPE.ACTIVE_SHARE],
          'orange',
          ['==', ['get', 'user_class_id'], AREA_TYPE.BASE],
          'yellow',
          'white',
        ],

        'fill-outline-color': 'yellow',
        'fill-opacity': [
          'case',
          ['==', ['get', 'user_class_id'], AREA_TYPE.FIELD],
          0.5,
          ['==', ['get', 'user_class_id'], AREA_TYPE.SHARE],
          0.5,
          ['==', ['get', 'user_class_id'], AREA_TYPE.ACTIVE_FIELD],
          0.5,
          ['==', ['get', 'user_class_id'], AREA_TYPE.ACTIVE_SHARE],
          0.8,
          ['==', ['get', 'user_class_id'], AREA_TYPE.BASE],
          0.5,
          0.5,
        ],
      },
    },
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 3,
        'circle-color': '#fbb03b',
      },
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'white',
        'line-width': 2,
        'line-opacity': 0.9,
      },
    },

    {
      id: 'gl-draw-polygon-and-line-vertex-halo-active',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
      paint: {
        'circle-radius': 5,
        'circle-color': '#FFF',
      },
    },

    {
      id: 'gl-draw-polygon-and-line-vertex-active',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
      paint: {
        'circle-radius': 3,
        'circle-color': 'yellow',
      },
    },

    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': 3,
      },
    },
    // polygon fill
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      paint: {
        'fill-color': '#000',
        'fill-outline-color': '#000',
        'fill-opacity': 0.1,
      },
    },
    // polygon outline
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': 3,
      },
    },
  ],
});
