import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { TextField, Grid, Button, Typography } from '@material-ui/core';

import { TypeSelector } from '../../components/form/type-selector';
import { useGetAPI } from '../../api/contractors/use-api';
import { CONTRACTOR_TYPES } from '../../enum/contractor-types';
import { useUpdateContractor, useCreateContractor } from './use-api-contractor';

const SignupSchema = Yup.object().shape({
  address: Yup.object({
    email: Yup.string().email('Невалидный почтовый ящик'),
  }),
  taxNumber: Yup.string().max(10, 'Максимум 10 симовлов').required('Пустое значение!'),
  type: Yup.string().required('Выберите тип контрагента'),
  name: Yup.string()
    .min(6, 'Минимум 6 символов')
    .max(255, 'Максимум 255 симовлов')
    .required('Пустое значение!'),
});

export const ContractorEditor = () => {
  const { id } = useParams();

  const { data, isLoading, error } = useGetAPI({ id });

  const onUpdateContractor = useUpdateContractor();
  const onCreateContractor = useCreateContractor();

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues: {
      id,
      name: '',
      lastName: '',
      firstName: '',
      midName: '',
      taxNumber: '',
      type: '',
      address: {
        physicalAddress: '',
        legalAddress: '',
        comment: '',
        email: '',
        phone: '',
      },
    },
    onSubmit: (values) => {
      id ? onUpdateContractor(values) : onCreateContractor({ ...values, id: uuid() });
    },
  });

  useEffect(() => {
    if (data) {
      formik.setValues(data);
    }
  }, [data]);

  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div style={{ width: '50%' }}>
        <Typography style={{ paddingTop: 8, paddingBottom: 8 }} variant="h4" component="h2">
          Карточка контрагента
        </Typography>
        <div style={{ color: 'red' }}>{error}</div>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item md={12}>
            <TextField
              fullWidth
              type="text"
              id="component-outlined"
              name="name"
              label="Наименование"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors?.name}
              helperText={formik.errors?.name}
            />
          </Grid>
          <Grid>
            <TypeSelector
              types={CONTRACTOR_TYPES}
              value={formik.values.type}
              error={formik.errors?.type}
              onChange={(value) => formik.setFieldValue('type', value)}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              id="component-outlined"
              name="taxNumber"
              label="ИНН"
              onChange={formik.handleChange}
              value={formik.values.taxNumber}
              error={formik.errors?.taxNumber}
              helperText={formik.errors?.taxNumber}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              id="component-outlined"
              name="address.phone"
              label="Телефон"
              onChange={formik.handleChange}
              value={formik.values.address.phone}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item>
            <TextField
              type="text"
              id="component-outlined"
              name="lastName"
              label="Фамилия"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              id="component-outlined"
              name="firstName"
              label="Имя"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              id="component-outlined"
              name="midName"
              label="Отчество"
              onChange={formik.handleChange}
              value={formik.values.midName}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              type="text"
              id="component-outlined"
              name="address.physicalAddress"
              label="Физический адрес"
              onChange={formik.handleChange}
              value={formik.values.address.physicalAddress}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              type="text"
              id="component-outlined"
              name="address.legalAddress"
              label="Юридический адрес"
              onChange={formik.handleChange}
              value={formik.values.address.legalAddress}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="text"
              id="component-outlined"
              name="address.email"
              label="E-mail"
              onChange={formik.handleChange}
              value={formik.values.address.email}
              error={formik.errors?.address?.email}
              helperText={formik.errors?.address?.email}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              type="text"
              id="component-outlined"
              name="address.comment"
              label="Комментарий"
              onChange={formik.handleChange}
              value={formik.values.address.comment}
            />
          </Grid>
          <Grid item md={12}>
            <Button
              disabled={!formik.isValid}
              variant="contained"
              color="primary"
              onClick={() => formik.submitForm()}
            >
              {id ? 'Изменить' : 'Создать'}{' '}
              {isLoading && (
                <CircularProgress style={{ color: 'red', marginLeft: 8, width: 16, height: 16 }} />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
