import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { shareAPI } from '../../api/share-controller';

export const useCreateShareAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ parentId, coordinates }) => shareAPI.create({ parentId, coordinates }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Пай создан!');
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;
        NotificationManager.error(errorMessage || error.message, 'Ошибка создания пая!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
