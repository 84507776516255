import turf from 'turf';

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const polygonToImage = (polygon, size = 100, markerCoordinates) => {
  if (!polygon) return;

  const bbox = turf.bbox(turf.polygon(polygon.coordinates));

  const xOffset = (bbox[2] - bbox[0]) * 0.1;
  const yOffset = (bbox[3] - bbox[1]) * 0.1;

  bbox[0] -= xOffset;
  bbox[1] -= yOffset;
  bbox[2] += xOffset;
  bbox[3] += yOffset;

  const poly = encodeURIComponent(
    JSON.stringify({
      type: 'Feature',
      properties: {
        fill: '#ffff00',
        stroke: '#ffffff',
        'stroke-width': 2,
      },
      layout: {
        'text-field': 'test',
        'text-color': 'black',
        'text-size': 14,
        'icon-image': 'field_white',
        'icon-offset': [0, -25],
      },
      geometry: polygon,
    }),
  );

  const point = encodeURIComponent(
    JSON.stringify({
      type: 'Feature',
      properties: {
        'marker-size': 's',
        'marker-color': 'orange',
      },
      geometry: {
        type: 'Point',
        coordinates: markerCoordinates,
      },
    }),
  );

  const overlay = markerCoordinates ? `geojson(${poly}),geojson(${point})` : `geojson(${poly})`;
  const bboxJson = JSON.stringify(bbox);

  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${overlay}/${bboxJson}/${size}x${size}?access_token=${accessToken}`;
};
