import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getContractorEndpoint = () => {
  return API_ENDPOINT + '/contractor';
};

export const contractorAPI = {
  all: async ({ partName, size, page, orderBy, order }) => {
    const { data } = await axios.get(getContractorEndpoint(), {
      params: {
        size,
        page,
        sort: orderBy + ',' + order,
        partName,
      },
    });
    return data;
  },

  get: async ({ id }) => {
    const { data } = await axios.get(getContractorEndpoint() + '/' + id);
    return data;
  },

  create: (data) => {
    return axios({
      method: 'post',
      url: getContractorEndpoint(),
      data,
    });
  },
  update: (data) => {
    return axios({
      method: 'post',
      url: getContractorEndpoint(),
      data,
    });
  },
  delete: ({ id }) => {
    return axios.delete(getContractorEndpoint() + '/' + id);
  },
};
