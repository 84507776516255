import React, { useEffect, useContext, useRef } from 'react';
import turf from 'turf';
import { useSelector, useDispatch } from 'react-redux';
import { MapContext } from 'react-mapbox-gl';

import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';

import { AREA_TYPE } from './consts';
import { draw } from './mapbox-draw';
import { useCreateGeozone } from './use-create-geozone';
import { useUpdateGeozone } from './use-update-geozone';
import { useDeleteGeozone } from './use-delete-geozone';
import { useCreateShare } from './use-create-share';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import 'mapbox-gl/dist/mapbox-gl.css';

const initBounds = {
  swLng: 31.937222613289606,
  swLat: 49.39850281447136,
  neLng: 31.95180122583369,
  neLat: 49.39349561845589,
};

function getCode(address) {
  if (!address) return null;
  const parts = address.split(', ');
  return parts[0];
}

export const PolygonEditor = () => {
  const [bounds] = useStateWithLocalStorage({
    key: 'map.bounds',
    defaultValue: initBounds,
  });

  const { fields, currentFieldId, currentShareId, mapBoxMode } = useSelector((state) => state);

  const drawCreateCallbackRef = useRef();

  const dispatch = useDispatch();

  const map = useContext(MapContext);

  const onCreateGeozone = useCreateGeozone();
  const onCreateShare = useCreateShare();
  const onUpdateGeozone = useUpdateGeozone();
  const onDeleteGeozone = useDeleteGeozone();

  useEffect(() => {
    if (!map) return;
    if (map.getSource('share_label') || map.getLayer('share_label')) return;
    map.addControl(draw);
  }, [map]);

  useEffect(() => {
    if (!map) return;

    map.off('draw.create', drawCreateCallbackRef.current);

    drawCreateCallbackRef.current = (e) => {
      currentFieldId ? onCreateShare(currentFieldId, e) : onCreateGeozone(e);
    };

    map.on('draw.create', drawCreateCallbackRef.current);
    return () => {
      map.off('draw.create', drawCreateCallbackRef.current);
    };
  }, [map, currentFieldId]);

  useEffect(() => {
    if (!map) return;
    if (map.getSource('share_label') || map.getLayer('share_label')) return;

    function onModeChange(mode) {
      dispatch({ type: 'SET_MAP_BOX_MODE', value: mode });
    }

    function onSelectionChange(e) {
      if (e.features[0]?.id) {
        const feature = e.features[0];

        if (
          feature.properties.class_id === AREA_TYPE.FIELD ||
          feature.properties.class_id === AREA_TYPE.ACTIVE_FIELD
        ) {
          dispatch({ type: 'SET_CURRENT_FIELD_ID', value: feature.id });
        } else {
          dispatch({ type: 'SET_CURRENT_SHARE_ID', value: feature.id });
        }
      }
    }

    map.on('draw.delete', onDeleteGeozone);
    map.on('draw.update', onUpdateGeozone);
    map.on('draw.modechange', onModeChange);
    map.on('draw.selectionchange', onSelectionChange);

    if (!currentFieldId) {
      map.fitBounds(
        [
          [bounds.swLng, bounds.swLat],
          [bounds.neLng, bounds.neLat],
        ],
        { duration: 0 },
      );
    }
  }, [map]);

  useEffect(() => {
    if (!draw) return;
    const mode = 'simple_select';
    draw.changeMode(mode);
    dispatch({ type: 'SET_MAP_BOX_MODE', value: mode });

    map.loadImage(PeopleAltIcon, (error, image) => {
      if (error) throw error;

      // Add the image to the map style.
      map.addImage('cat', image);
    });
  }, [draw]);

  useEffect(() => {
    if (map.getSource('share_label') || map.getLayer('share_label')) return;

    map.addSource('share_label', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }, [map]);

  useEffect(() => {
    if (map.getSource('field_label') || map.getLayer('field_label')) return;

    map.addSource('field_label', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }, [map]);

  useEffect(() => {
    if (fields?.size !== 0 && mapBoxMode?.mode !== 'draw_polygon') {
      draw.deleteAll();

      fields
        .flatMap((field, i) => {
          return [
            {
              type: 'Feature',
              geometry: field.polygon,
              id: field.id,

              properties: {
                name: field.name,
                geocodeAddress: getCode(field.geocodeAddress),
                calculatedArea: field.calculatedArea,
                class_id:
                  currentFieldId && currentFieldId === field.id
                    ? AREA_TYPE.ACTIVE_FIELD
                    : AREA_TYPE.FIELD,
              },
            },
            ...field.shares.map((share) => ({
              type: 'Feature',
              geometry: share.polygon,
              id: share.id,
              properties: {
                calculatedArea: share.calculatedArea,
                class_id:
                  currentShareId && currentShareId === share.id
                    ? AREA_TYPE.ACTIVE_SHARE
                    : AREA_TYPE.SHARE,
              },
            })),
          ];
        })
        .forEach((field) => {
          draw.add(field);
        });

      const fieldLabels = [];
      const shareLabels = [];

      const all = draw.getAll();
      if (all && all.features) {
        all.features.forEach(function (feature) {
          if (
            feature.geometry.coordinates.length > 0 &&
            feature.geometry.coordinates[0].length > 3
          ) {
            const point = turf.centroid(feature, {
              type: 'area',
              label: 'm²',
            });
            point.properties = {
              area: feature.properties.calculatedArea.toFixed(2),
              name: feature.properties.name || feature.properties.geocodeAddress,
            };

            if (
              feature.properties.class_id === AREA_TYPE.FIELD ||
              feature.properties.class_id === AREA_TYPE.ACTIVE_FIELD
            ) {
              fieldLabels.push(point);
            } else {
              shareLabels.push(point);
            }
          }
        });
      }

      if (map.getLayer('share_label')) map.removeLayer('share_label');

      // measurements layer
      map.addLayer({
        id: 'share_label',
        source: 'share_label',
        type: 'symbol',
        paint: {
          'text-color': 'black',
        },
        layout: {
          'text-field': '{area} га',
          'text-size': 12,
        },
      });

      map.getSource('share_label').setData({
        type: 'FeatureCollection',
        features: shareLabels,
      });

      if (map.getLayer('field_label')) map.removeLayer('field_label');

      map.addLayer({
        id: 'field_label',
        source: 'field_label',
        type: 'symbol',
        paint: {
          'text-color': 'white',
        },
        layout: {
          'text-field': 'Поле ({area} га)\n{name}\n',
          'text-size': 14,
          'icon-image': 'field_white',
          'icon-offset': [0, -25],
        },
      });

      map.getSource('field_label').setData({
        type: 'FeatureCollection',
        features: fieldLabels,
      });
    }
  }, [fields, currentFieldId]);

  useEffect(() => {
    if (currentFieldId) {
      var all = draw.getAll();
      if (all && all.features) {
        const currentField = all.features.find((field) => field.id === currentFieldId);
        if (currentField) {
          const bbox = turf.bbox(currentField);

          map.fitBounds(
            [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]],
            ],
            { duration: 0 },
          );
          dispatch({
            type: 'SET_BOUNDS',
            value: {
              swLng: bbox[0],
              swLat: bbox[1],
              neLng: bbox[2],
              neLat: bbox[3],
            },
          });
        }
      }
    }
  }, [currentFieldId]);

  useEffect(() => {
    if (currentShareId) {
      var all = draw.getAll();
      if (all && all.features) {
        const currentShare = all.features.find((field) => field.id === currentShareId);
        if (currentShare) {
          const bbox = turf.bbox(currentShare);

          map.fitBounds(
            [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]],
            ],
            { duration: 0 },
          );
          dispatch({
            type: 'SET_BOUNDS',
            value: {
              swLng: bbox[0],
              swLat: bbox[1],
              neLng: bbox[2],
              neLat: bbox[3],
            },
          });
        }
      }
    }
  }, [currentShareId]);

  return null;
};
