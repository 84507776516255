import React from 'react';
import { List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FieldItem } from './field-item';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export function FieldList() {
  const { fields } = useSelector((state) => state);
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {fields.map((field) => {
        return <FieldItem field={field} />;
      })}
    </List>
  );
}
