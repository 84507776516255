import React from 'react';
import { Button, Grid } from '@material-ui/core';

export const TypeSelector = ({
  types = { one: 'Один', two: 'Два', three: 'Три' },
  value = 'two',
  onChange = () => {},
  error,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        {Object.keys(types).map((type) => (
          <Grid item>
            <Button
              color="primary"
              onClick={() => onChange(type)}
              variant={value === type ? 'contained' : 'outlined'}
            >
              {types[type]}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container style={{ fontSize: 12, color: 'red', justifyContent: 'center' }}>
        <Grid item>{error}</Grid>
      </Grid>
    </>
  );
};
