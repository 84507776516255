import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import { App } from './app/app';
import 'react-notifications/lib/notifications.css';
import store from './store';
import axios from 'axios';
import keycloak from './keycloak';

import './App.css';

axios.interceptors.request.use(
  (request) => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //   console.log('Auth error!!!!');
    // }
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient();

function Index() {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <NotificationContainer />
        </Provider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

export default Index;
