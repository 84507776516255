import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUpdateAPI } from '../../api/fields/use-api';

export const useUpdateGeozone = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data, isSuccess } = useUpdateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch({ type: 'UPDATE_FIELD', value: data });
    }
  }, [dispatch, data, isSuccess]);

  const onUpdateArea = useCallback(
    (e) => {
      e.features.forEach((area) => {
        mutate({ id: area.id, coordinates: area.geometry.coordinates });
      });
    },
    [mutate],
  );

  return onUpdateArea;
};
