import axios from 'axios';
import { uuid } from 'uuidv4';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getFieldEndpoint = () => {
  return API_ENDPOINT + '/field';
};

export const fieldAPI = {
  all: async ({ swLat, swLng, neLat, neLng, zoom }) => {
    const { data } = await axios.get(getFieldEndpoint(), {
      params: {
        swLat,
        swLng,
        neLat,
        neLng,
        zoom,
        size: 1000,
        sort: 'audit.createdDate,desc',
      },
    });
    return data;
  },

  pageableAll: async ({ page, size, sort }) => {
    const { data } = await axios.get(getFieldEndpoint(), {
      params: {
        page,
        size,
        sort,
      },
    });
    return data;
  },

  get: async (id) => {
    const { data } = await axios.get(getFieldEndpoint() + '/' + id);
    return data;
  },

  create: ({ coordinates }) => {
    return axios({
      method: 'post',
      url: getFieldEndpoint(),
      data: {
        id: uuid(),
        polygon: {
          coordinates,
          type: 'Polygon',
        },
      },
    });
  },
  update: ({ id, coordinates, name }) => {
    return axios({
      method: 'post',
      url: getFieldEndpoint(),
      data: {
        id,
        name,
        polygon: {
          coordinates,
          type: 'Polygon',
        },
      },
    });
  },
  delete: ({ id }) => {
    return axios.delete(getFieldEndpoint() + '/' + id);
  },
};
