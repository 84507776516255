import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

export function Controls() {
  const dispatch = useDispatch();
  const { currentFieldId, mapBoxMode } = useSelector((state) => state);

  return (
    <div style={{ position: 'absolute', top: 10, left: 10 }}>
      <Button
        variant="contained"
        color="primmary"
        onClick={() => dispatch({ type: 'SET_CURRENT_FIELD_ID', value: null })}
      >
        {currentFieldId ? `Режим пая` : 'Режим поля'} {mapBoxMode?.mode}
      </Button>
    </div>
  );
}
