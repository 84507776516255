import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useAPI } from './use-api';
import moment from 'moment';
import { withStyles, makeStyles, lighten } from '@material-ui/core/styles';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import TablePaginationActions from '../pagination';

const headCells = [
  { id: 'taxNumber', numeric: false, disablePadding: false, label: 'ИНН' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Наименование' },
  { id: 'audit.createdDate', numeric: false, disablePadding: false, label: 'Дата создания' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F0F0',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {},
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Контрагенты
      </Typography>
    </Toolbar>
  );
};

export const ContractorFinder = ({ open = false, onClose = () => {} }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(8);

  const [partName, setPartName] = useState();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('audit.createdDate');

  const { data, isLoading } = useAPI({ partName, page, size, orderBy, order });

  const handler = useCallback(
    debounce((e) => setPartName(e.target.value), 1000),
    [],
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading) return <div>Loading...</div>;

  const list = () => (
    <div style={{ padding: 32 }}>
      <CloseIcon
        style={{ position: 'absolute', right: 16, top: 8, cursor: 'pointer' }}
        onClick={() => onClose()}
      />
      <Typography>Быстрый поиск контрагента</Typography>
      <TextField
        type="text"
        id="component-outlined"
        name="name"
        label="Имя контрагента"
        onChange={handler}
        // value={formik.values.number}
        // error={formik.errors?.number}
        // helperText={formik.errors?.number}
      />
      <EnhancedTableToolbar />

      <TableContainer>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {data.content.map((row) => (
              <StyledTableRow
                style={{ cursor: 'pointer' }}
                hover
                key={row.id}
                onClick={() => {
                  onClose(row);
                }}
              >
                <StyledTableCell align="right">{row.taxNumber}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">
                  {moment(row.createdDate).format('LL')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={data.totalElements}
                rowsPerPage={size}
                page={page}
                labelRowsPerPage={'Кол-во контрагентов на странице'}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e) => {
                  setSize(parseInt(e.target.value, 10));
                  setPage(0);
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <Drawer style={{ width: '50%' }} anchor={'right'} open={open} onClose={() => {}}>
      <div style={{ width: '100%' }}>{list()}</div>
    </Drawer>
  );
};
