import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import turf from 'turf';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import PolyIcon from '@material-ui/icons/Dashboard';

import { useGetAllAPI } from '../../api/fields/use-api';
import { Loader } from '../../components/loader';

import FilterListIcon from '@material-ui/icons/FilterList';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'audit.createdDate', numeric: false, disablePadding: false, label: 'Дата создания' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Имя' },
  { id: 'geocodeAddress', numeric: false, disablePadding: false, label: 'Адрес' },
  { id: 'calculatedArea', numeric: false, disablePadding: false, label: 'Площадь (га)' },
  { id: 'totalShares', numeric: false, disablePadding: false, label: 'Кол-во паев (шт)' },
];

function gotoMapEditor(field, dispatch, history) {
  const bbox = turf.bbox(field.polygon);

  dispatch({
    type: 'SET_BOUNDS',
    value: {
      swLng: bbox[0],
      swLat: bbox[1],
      neLng: bbox[2],
      neLat: bbox[3],
    },
  });

  dispatch({ type: 'SET_CURRENT_FIELD_ID', value: field.id });

  history.push('/fields-editor');
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell /> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell key="buttons" align="center" padding="normal">
          Управление
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <>
      <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Справочник полей
        </Typography>

        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export function FieldViewer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [fields, setFields] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('audit.createdDate');

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState();

  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetAllAPI({ page, size, sort: orderBy + ',' + order });

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [rows, setRows] = useState([]);

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setFields(data.content);
      setTotal(data.totalElements);
    }
  }, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) return <Loader text="Идет загрузка справочника полей." />;
  return (
    <>
      <div className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setShowCreateDialog={setShowCreateDialog}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {fields.map((field, index) => {
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={field.id}>
                      {/* <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell> */}
                      <TableCell style={{ cursor: 'pointer' }} scope="row">
                        <div>
                          <EditIcon
                            style={{ color: 'rgb(67, 160, 71)' }}
                            onClick={() => history.push(`/field-editor/${field.id}`)}
                          />
                          <PolyIcon
                            style={{ color: 'rgb(67, 160, 71)' }}
                            onClick={() => gotoMapEditor(field, dispatch, history)}
                          />
                        </div>
                        {field.id}
                      </TableCell>
                      <TableCell scope="row">
                        {moment(field.createdDate).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell scope="row">{field.name}</TableCell>
                      <TableCell scope="row">{field.geocodeAddress}</TableCell>
                      <TableCell scope="row">{field.calculatedArea}</TableCell>
                      <TableCell scope="row">{field.shares.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Customer</TableCell>
                                  <TableCell align="right">Amount</TableCell>
                                  <TableCell align="right">Total price ($)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell component="th" scope="row">
                                    test
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Кол-во полей на странице'}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
