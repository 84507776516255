import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { ImageListItem, ImageList, Card, CardContent, Typography } from '@material-ui/core';

import { useCreateImage } from './use-api';

export const ImageUploader = ({ onSetImages = () => {}, initImages = [] }) => {
  const [isLoading, onCreateMessage, data, isSuccess] = useCreateImage();

  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onCreateMessage({ images: previewImages });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setImages([...images, ...data.data]);
      onSetImages([...images, ...data.data]);
      setPreviewImages([]);
    }
  }, [data, isSuccess]);

  useEffect(() => {
      if (initImages && initImages.length) {
          setImages([...initImages]);
      }
  }, [initImages]);

  return (
    <div style={{ padding: 8 }}>
      <Backdrop open={isLoading} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={{ margin: 8 }} variant="contained" color="primary" component="label">
          Выбрать файлы
          <input
            hidden
            accept="image/*"
            type="file"
            name="image"
            multiple
            onChange={(e) => {
              setPreviewImages([...previewImages, ...e.currentTarget.files]);
            }}
          />
        </Button>

        {previewImages.length ? (
          <Button
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
            disabled={!previewImages.length}
          >
            Загрузить
          </Button>
        ) : (
          []
        )}
      </div>

      {previewImages.length ? (
        <div style={{ paddingTop: 8 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Предварительный просмотр</Typography>
              <ImageList rowHeight={170} cols={12}>
                {previewImages.map((image, idx) => {
                  return (
                    <ImageListItem key={idx} cols={1} style={{ marginTop: 8 }}>
                      <CloseIcon
                        onClick={() => {
                          const newArray = previewImages.filter((v, i) => i !== idx);
                          formik.setFieldValue('images', newArray);
                          setPreviewImages(newArray);
                        }}
                        style={{
                          position: 'absolute',
                          right: 0,
                          zIndex: 200,
                          backgroundColor: 'red',
                          color: 'white',
                          cursor: 'pointer',
                        }}
                      />
                      <img src={URL.createObjectURL(image)} alt={'Предварительный просмотр'} />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </CardContent>
          </Card>
        </div>
      ) : (
        []
      )}

      <div style={{ paddingTop: 8 }}>
        <ImageList rowHeight={340} cols={6}>
          {images.map((image, idx) => {
            return (
              <ImageListItem key={idx} cols={1}>
                <CloseIcon
                  onClick={() => {
                    const newArray = images.filter((v, i) => i !== idx);
                    setImages(newArray);
                    onSetImages(newArray);
                  }}
                  style={{
                    position: 'absolute',
                    right: 0,
                    zIndex: 200,
                    backgroundColor: 'red',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
                <img src={image.image} alt={image.name} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    </div>
  );
};
