import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateShareAPI } from './use-share-api';

export const useCreateShare = () => {
  const dispatch = useDispatch();
  const { currentFieldId } = useSelector((state) => state);
  const { mutate, isLoading, data, isSuccess } = useCreateShareAPI();

  useEffect(() => {
    if (data?.data && isSuccess) {
      dispatch({ type: 'ADD_SHARE', value: data.data });
    }
  }, [dispatch, data, isSuccess]);

  const onCreateShare = useCallback(
    (currentFieldId, e) => {
      e.features.forEach((area) => {
        mutate({ parentId: currentFieldId, coordinates: area.geometry.coordinates });
      });
    },
    [mutate],
  );

  return onCreateShare;
};
