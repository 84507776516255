import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { contractorAPI } from '../contractor-controller';

export const useAPI = ({ neLng, neLat, swLng, swLat }, zoom) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['contractors', { neLng, neLat, swLng, swLat, zoom }],
    () => contractorAPI.all({ neLng, neLat, swLng, swLat, zoom }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAllAPI = ({ page, size, sort }) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['contractors', { page, size, sort }],
    () => contractorAPI.pageableAll({ page, size, sort }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAPI = ({ id }) => {
  const { isLoading, error, data } = useQuery(
    ['contractor', { id }],
    () => contractorAPI.get({ id }),
    {
      onError: (error) => {
        NotificationManager.error(error.message);
      },
      enabled: !!id,
    },
  );

  return { isLoading, error, data };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    (data) => contractorAPI.create(data),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Контрагент создан!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания контрагента!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    (data) => contractorAPI.update(data),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Контрагент изменен!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка изменения контрагента!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => contractorAPI.delete({ id }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Пай удален!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления пая!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
