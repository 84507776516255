import { useQuery, useMutation } from 'react-query';
import { shareAPI } from '../../api/share-controller';

export const useAPI = ({ page, size, order, orderBy }) => {
  const {
    isIdle,
    isLoading,
    error,
    refetch,
    data: shares,
  } = useQuery(
    ['shares', { page, size, order, orderBy }],
    () => shareAPI.all({ page, size, order, orderBy }),
    {
      keepPreviousData: true,
      onError: (error) => {
        //alert(JSON.stringify(error.message));
      },
    },
  );

  return { isLoading, isIdle, error, refetch, shares: shares };
};

export const useGetAPI = ({ id }) => {
  const {
    isLoading,
    error,
    data: shares,
  } = useQuery(
    ['share', id],
    (id) => {
      shareAPI.get({ id });
    },
    {
      onError: (error) => {
        // alert(JSON.stringify(error.message));
      },
    },
  );

  return { isLoading, error, shares };
};
