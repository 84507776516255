import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {uuid} from 'uuidv4';
import moment from 'moment';
import {NotificationManager} from 'react-notifications';
import {useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import {Button, Grid, TextField} from '@material-ui/core';
import Calendar from '../../components/calendar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import {ImageListItem, ImageList, Card, CardContent, Typography} from '@material-ui/core';
import {ImageUploader} from '../../components/image-uploader';
import {ContractorFinder} from '../../components/contractor-finder';
import {ShareFinder} from '../../components/share-finder';

import {useUpdateContract, useCreateContract} from './use-api-contract';
import {useGetAPI} from '../../api/contracts/use-api';

const SignupSchema = Yup.object().shape({
    number: Yup.string()
        .min(3, 'Минимум 3 символов')
        .max(64, 'Максимум 255 симовлов')
        .required('Пустое значение!'),

    contractor: Yup.object({
        name: Yup.string().required('Пустое значение!'),
    }),
    share: Yup.object({
        name: Yup.string().required('Пустое значение!'),
    }),

    endDate: Yup.date().min(
        Yup.ref('signingDate'),
        'Дата окончания не может быть раньше даты подписания!',
    ),
});

export const ContractEditor = () => {
    const {id} = useParams();

    const {data, isLoading, error} = useGetAPI({id});

    const onUpdateContract = useUpdateContract();
    const onCreateContract = useCreateContract();

    const [showContractorsFinder, setShowContractorsFinder] = useState(false);
    const [showSharesFinder, setShowSharesFinder] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (data?.files?.length ) {
            setImages(data?.files);
        }
    },[data?.files])

    const formik = useFormik({
        initialValues: {
            id,
            number: '',
            contractor: {
                id: '',
                name: '',
            },
            share: {
                id: '',
                name: '',
            },
            signingDate: '',
            endDate: '',
        },
        validationSchema: SignupSchema,
        onSubmit: (values) => {
            if (images.length === 0) {
                NotificationManager.warning('У договір не було додано хоча б один скан документа!');
                return;
            }

            if (id) {

                onUpdateContract({
                    ...values,
                    files: images.map((i) => ({
                        id: i.id,
                    })),
                })
            } else {
                onCreateContract({
                    ...values,
                    files: images.map((i) => ({
                        id: i.id,
                    })),
                    id: uuid(),
                });
            }
        },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                signingDate: moment.utc(data.signingDate),
                endDate: moment.utc(data.endDate),
                share: {id: data.share.id, name: data.share.id}
            });
            console.log({data});
        }
    }, [data]);



    return (
        <div>
            <div
                style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <div style={{width: '50%'}}>
                    <Typography style={{paddingTop: 8, paddingBottom: 8}} variant="h4" component="h2">
                        Карточка договора
                    </Typography>

                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item md={12}>
                            <TextField
                                type="text"
                                id="component-outlined"
                                name="number"
                                label="Номер договора"
                                onChange={formik.handleChange}
                                value={formik.values?.number}
                                error={!!formik.errors?.number}
                                helperText={formik.errors?.number}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Calendar
                                label="Дата подписания"
                                value={formik.values.signingDate}
                                onChange={(v) => {
                                    formik.setFieldValue('signingDate', v);
                                }}
                                error={!!formik.errors?.signingDate}
                                helperText={formik.errors?.signingDate}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Calendar
                                label="Дата окончания"
                                value={formik.values.endDate}
                                onChange={(v) => {
                                    formik.setFieldValue('endDate', v);
                                }}
                                error={!!formik.errors?.endDate}
                                helperText={formik.errors?.endDate}
                            />
                        </Grid>
                    </Grid>
                    <TextField type="hidden" name="contractorId" value={formik.values.contractorId}/>
                    <TextField
                        fullWidth
                        type="text"
                        id="component-outlined"
                        name="contractor.name"
                        label="Контрагент"
                        onClick={() => setShowContractorsFinder(true)}
                        value={formik.values.contractor.name}
                        error={!!formik.errors?.contractor?.name}
                        helperText={formik.errors?.contractor?.name}
                    />

                    <TextField type="hidden" name="shareId" value={formik.values.shareId}/>
                    <TextField
                        fullWidth
                        onClick={() => setShowSharesFinder(true)}
                        type="text"
                        id="component-outlined"
                        name="share.name"
                        label="Земельный участок (пай)"
                        value={formik.values.share.name}
                        error={!!formik.errors?.share?.name}
                        helperText={formik.errors?.share?.name}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', padding: 10}}>
                <Button
                    disabled={!formik.isValid}
                    variant="contained"
                    color="primary"
                    onClick={() => formik.submitForm()}
                >
                    {id ? 'Изменить договор' : 'Создать договор'}{' '}
                    {isLoading && (
                        <CircularProgress style={{color: 'red', marginLeft: 8, width: 16, height: 16}}/>
                    )}
                </Button>
            </div>

            <ContractorFinder
                open={showContractorsFinder}
                onClose={(data) => {
                    if (data) {
                        formik.setFieldValue('contractor.id', data.id);
                        formik.setFieldValue('contractor.name', data.name);
                    }
                    setShowContractorsFinder(false);
                }}
            />

            <ShareFinder
                open={showSharesFinder}
                onClose={(data) => {
                    if (data) {
                        formik.setFieldValue('share.id', data.id);
                        formik.setFieldValue('share.name', data.id);
                    }
                    setShowSharesFinder(false);
                }}
            />
            <ImageUploader initImages={data?.files || []} onSetImages={(images) => setImages(images)}/>
        </div>
    );
};
