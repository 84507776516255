import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { useUpdateAPI } from '../../api/fields/use-api';

export const useUpdateGeozone = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data, error, isSuccess } = useUpdateAPI();

  useEffect(() => {
    if (data && isSuccess) {
    }
  }, [dispatch, data, isSuccess]);
  useEffect(() => {
    if (error) NotificationManager.error(error);
  }, [error]);

  const onUpdateArea = useCallback(
    (area) => {
      mutate({ id: area.id, name: area.name, coordinates: area.polygon.coordinates });
    },
    [mutate],
  );

  return onUpdateArea;
};
