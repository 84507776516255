import React, { useEffect, useState } from 'react';
import turf from 'turf';
import { NotificationManager } from 'react-notifications';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from '@material-ui/core';
import { polygonToImage } from '../../utils/polygon-to-preview';
import { useUpdateShare } from './use-update-share';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function formikValuesToPolygon(field, values) {
  const newField = field.coordinates[0].slice(1).reduce((acc, row, i) => {
    acc[i] = [values[`${i}_lat`], values[`${i}_lng`]];
    return acc;
  }, []);

  return { ...field, coordinates: [[newField[newField.length - 1], ...newField]] };
}

export default function PolygonTable({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentPoint } = useSelector((state) => state);

  const onUpdateShare = useUpdateShare();

  const [pointIdx, setPointIdx] = useState();
  const [field, setField] = useState();
  const [previewField, setPreviewField] = useState();

  function restoreValues() {
    formik.setValues(
      field.coordinates[0].reduce((prev, row, i) => {
        return { ...prev, [`${i}_lat`]: row[0], [`${i}_lng`]: row[1] };
      }, {}),
    );
  }

  useEffect(() => {
    if (data && data.polygon) {
      setField(data.polygon);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      const polygon = formikValuesToPolygon(field, formik.values);
      const centerOfField = turf.centroid(polygon);
      const pointOfCenter = centerOfField?.geometry?.coordinates;
      const area = turf.area(turf.polygon(polygon.coordinates));

      if (!pointOfCenter[0] || !pointOfCenter[1] || area > 5000000) {
        NotificationManager.error(
          'Невозможно обновить поле, так как неправильная геометрия или площадь очень большая!',
        );
      } else {
        onUpdateShare({ ...data, polygon });
      }
    },
  });

  useEffect(() => {
    if (!field) return;
    const polygon = field.coordinates[0].reduce((prev, row, i) => {
      return { ...prev, [`${i}_lat`]: row[0], [`${i}_lng`]: row[1] };
    }, {});
    formik.setValues({ ...polygon, name: data.name });
  }, [field, data]);

  useEffect(() => {
    if (!field) return;
    setPreviewField(formikValuesToPolygon(field, formik.values));
  }, [formik.values, field]);

  useEffect(() => {
    if (!pointIdx) return;
    dispatch({ type: 'SET_CURRENT_POINT', value: previewField.coordinates[0][pointIdx] });
  }, [pointIdx, previewField, dispatch]);

  return (
    <>
      <Grid container>
        <Grid item>
          <div style={{ paddingTop: 8 }}>
            <div style={{ backgroundColor: 'silver' }}>
              {field && (
                <img
                  width={300}
                  height={300}
                  src={polygonToImage(previewField, 300, currentPoint)}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item style={{ margin: 8 }}>
          {field && (
            <>
              <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>lat</TableCell>
                      <TableCell>lng</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {field.coordinates[0].slice(1).map((row, i) => (
                      <TableRow
                        key={(row, i)}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPointIdx(i + 1)}
                      >
                        <TableCell>
                          <TextField
                            type="number"
                            step="0.00000001"
                            min="-90"
                            max="90"
                            id="component-outlined"
                            name={`${i}_lat`}
                            label="lat"
                            onChange={formik.handleChange}
                            value={formik.values[`${i}_lat`]}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            step="0.00000001"
                            min="-180"
                            max="180"
                            id="component-outlined"
                            name={`${i}_lng`}
                            label="lng"
                            onChange={formik.handleChange}
                            value={formik.values[`${i}_lng`]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ padding: 8 }}>
                <Button color="primary" variant="contained" onClick={() => formik.handleSubmit()}>
                  Сохранить изменения
                </Button>
              </div>
              <div style={{ padding: 8 }}>
                <Button color="secondary" variant="contained" onClick={() => restoreValues()}>
                  Восстановить начальные значения
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
