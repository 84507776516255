import { useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { imageAPI } from '../image-controller';

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    (data) => imageAPI.create(data),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Изображение создано!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания изображения!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
