import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TablePaginationActions from '../../components/pagination';

import { CONTRACTOR_TYPES } from '../../enum/contractor-types';

import { useAPI } from './use-api';

moment.locale('ru');

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Контрагенты
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F0F0',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {},
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ИД' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Тип' },
  { id: 'taxNumber', numeric: false, disablePadding: false, label: 'ИНН' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Наименование' },
  { id: 'audit.createdDate', numeric: false, disablePadding: false, label: 'Дата создания' },
  { id: 'totalContracts', numeric: false, disablePadding: false, label: 'Кол-во договоров' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const Contractors = () => {
  const classes = useStyles();

  const history = useHistory();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(8);

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('audit.createdDate');

  const { data, isLoading } = useAPI({ page, size, orderBy, order });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <div style={{ padding: 8 }}>
        <Button variant="outlined" onClick={() => history.push('/contractor-editor')}>
          Создать
        </Button>
      </div>
      <EnhancedTableToolbar />

      <TableContainer>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {data.content.map((row) => (
              <StyledTableRow
                style={{ cursor: 'pointer' }}
                hover
                key={row.id}
                onClick={() => history.push('/contractor-editor/' + row.id)}
              >
                <StyledTableCell align="right">{row.id}</StyledTableCell>
                <StyledTableCell align="right">{CONTRACTOR_TYPES[row.type]}</StyledTableCell>
                <StyledTableCell align="right">{row.taxNumber}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">
                  {moment(row.createdDate).format('LL')}
                </StyledTableCell>
                <StyledTableCell align="right">{row.totalContracts}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={'Кол-во на странице'}
                rowsPerPageOptions={[8, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={data.totalElements}
                rowsPerPage={size}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e) => {
                  setSize(parseInt(e.target.value, 10));
                  setPage(0);
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
