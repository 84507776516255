import React, { useState } from 'react';
import SplitPane from 'react-split-pane';
import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import { MapPage } from './map';
import { FieldList } from './field-list';
import './index.css';

export function Fields() {
  const [size, setSize] = useStateWithLocalStorage({
    key: 'map.size',
    defaultValue: '30%',
  });

  const [proxySize, setProxySize] = useState(size);

  return (
    <div style={{ position: 'absolute', top: 66, left: 0, bottom: 0, right: 0 }}>
      <SplitPane
        split="vertical"
        primary="first"
        defaultSize={size}
        size={size}
        onChange={(size) => setSize(size)}
        onDragFinished={() => setProxySize(size)}
        minSize={150}
        maxSize={-500}
      >
        <div style={{ overflowY: 'scroll', height: 'calc(100vh - 64px)' }}>
          <FieldList />
        </div>
        <div>
          <MapPage size={proxySize} />
        </div>
      </SplitPane>
    </div>
  );
}
