import { useCallback } from 'react';
import { useCreateAPI } from '../../api/images/use-api';

export const useCreateImage = () => {
  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  const onCreateImage = useCallback((data) => mutate(data), [mutate]);

  return [isLoading, onCreateImage, data, isSuccess];
};
