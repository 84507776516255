import { useQuery } from 'react-query';
import { NotificationManager } from 'react-notifications';

import { shareAPI } from '../../api/share-controller';

export const useAPI = ({ cadastral, page, size, order, orderBy }) => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['shares', { cadastral, page, size, order, orderBy }],
    () => shareAPI.all({ cadastral, page, size, order, orderBy }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useGetAPI = ({ id }) => {
  const { isLoading, error, data } = useQuery(
    ['share', id],
    (id) => {
      shareAPI.get({ id });
    },
    {
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, error, data };
};
