export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELDS':
      return { ...state, fields: action.value };

    case 'ADD_FIELD':
      return { ...state, fields: [...state.fields, action.value] };

    case 'SET_CURRENT_POINT':
      return { ...state, currentPoint: action.value };

    case 'SET_BOUNDS':
      return { ...state, bounds: action.value };

    case 'ADD_SHARE':
      const { parentId } = action.value;

      const parentIndex = state.fields.findIndex((field) => field.id === parentId);

      const fields = state.fields;

      fields[parentIndex] = {
        ...fields[parentIndex],
        shares: [...fields[parentIndex].shares, action.value],
      };

      return { ...state, fields: [...fields] };

    case 'SET_CURRENT_FIELD_ID':
      return { ...state, currentFieldId: action.value };

    case 'SET_CURRENT_SHARE_ID':
      return { ...state, currentShareId: action.value };

    case 'SET_MAP_BOX_MODE':
      return { ...state, mapBoxMode: action.value };

    default:
      return state;
  }
};
